@media screen and (min-width: 1550px) {
    .fashion-img{
        bottom: 6rem
    }
}
.header-main{
    padding: 20px 3% 20px 3%;
}
@media screen and (max-width: 1200px) {
    .header-main{
        padding: 20px 1% 20px 1%;
    }
}

@media screen and (max-width: 450px) {
    .fashion-img{
        bottom: 3rem;
        /* width: 3rem; */
    }
}