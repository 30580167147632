.pricing_main{
    padding: 20px 12% 20px 12%
}

@media screen and (max-width: 1280px) {
   .pricing_main{
       padding: 20px 0% 20px 0%
  }
}
@media screen and (max-width: 1023px) {
   .pricing_main{
       padding: 20px 2% 20px 2%
  }
}
@media screen and (max-width: 640px) {
   .pricing_main{
       padding: 20px 0% 20px 0%
  }
}