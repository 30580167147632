@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(31, 62, 102, 1);
  border-radius: 50vh;
  /* border: 3px solid #edf2f7; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 62, 102, 1);
}