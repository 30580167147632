/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__container {
    max-width: 550px;
    margin: auto;
  }
  
  /* gives us the illusion of a "centered" slide */
  .carousel__slider {
    padding-left: 25%;
    padding-right: 25%;
  }
  
  /* gives us the illusion of spaces between the slides */
  .carousel__inner-slide {
    width: calc(100% - 50px);
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 10px;
  }
  