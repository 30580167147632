.brands_main{
    padding: 20px 5% 20px 5%
}

@media screen and (max-width: 1200px) {
   .brands_main{
       padding: 20px 5% 20px 5%
  }
}
@media screen and (max-width: 1023px) {
   .brands_main{
       padding: 20px 2% 20px 2%
  }
}